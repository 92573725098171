<template>
  <component :is="commonComponent" id="disclamimer" title="Novel&Genius Online">
    <section id="disclamimer-content" class="pc_padding">
      <div class="common-content-head">
        Introduction
      </div>

      <div class="common-content-row">
        Welcome to Novelgenius.online (hereinafter referred to as &quot;the Website&quot;). Please
        read the following
        disclaimer carefully before using the Website. By accessing and using the Website, you agree
        to the terms outlined in
        this disclaimer. If you do not agree with these terms, please do not use the Website.
      </div>

      <div class="common-content-head">
        Content Accuracy
      </div>

      <div class="common-content-row">
        All content provided on the Website is generated by artificial intelligence. While we strive
        to ensure the accuracy
        and completeness of the content, we make no guarantees regarding its accuracy, completeness,
        or suitability. You use
        the content at your own risk.
      </div>

      <div class="common-content-head">
        No Legal Advice
      </div>

      <div class="common-content-row">
        The content provided on the Website is for entertainment and reference purposes only and
        should not be considered
        professional legal, medical, financial, or other advice. We are not responsible for any loss
        or damage resulting from
        reliance on the content of the Website.
      </div>

      <div class="common-content-head">
        Third-Party Links
      </div>

      <div class="common-content-row">
        The Website may contain links to third-party websites. We are not responsible for the
        content or privacy policies of
        these third-party websites. Accessing these links is at your own risk, and you should comply
        with the terms and
        policies of these third-party websites.
      </div>

      <div class="common-content-head">
        Service Interruptions
      </div>

      <div class="common-content-row">
        We do not guarantee that the content, services, or features of the Website will be
        uninterrupted or error-free, or
        that any defects will be corrected. We are not liable for any loss or damage resulting from
        your use of or inability
        to use the Website.
      </div>

      <div class="common-content-head">
        Limitation of Liability
      </div>

      <div class="common-content-row">
        To the maximum extent permitted by applicable law, we shall not be liable for any direct,
        indirect, incidental,
        special, or consequential damages arising out of or in connection with your use or inability
        to use the Website,
        including but not limited to loss of profits, data loss, or business interruption.
      </div>

      <div class="common-content-head">
        Modifications and Termination
      </div>

      <div class="common-content-row">
        We reserve the right to modify, suspend, or terminate the Website or any part of it at any
        time without notice. We
        also reserve the right to modify this disclaimer at any time. The modified disclaimer will
        be effective immediately
        upon posting on the Website. Your continued use of the Website constitutes your acceptance
        of the modified disclaimer.
      </div>

      <div class="common-content-head">
        Contact Us
      </div>

      <div class="common-content-row">
        If you have any questions about this disclaimer or need further information, please contact
        us at:
      </div>

      <div class="common-content-row">
        Email: novelgenius.support@gmail.com
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Disclaimer | Novelgenius.online',
      meta: [
        {
          name: 'description',
          content: 'Discover a world of AI-generated novels and stories at Novelgenius.online. Dive into unique, imaginative tales created by advanced artificial intelligence. Perfect for readers seeking fresh and innovative content.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>